@import "scss/variables";

.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 10px;
  gap: 20px;
  .Container {
    flex: 1;
  }
  .Header {
    margin-bottom: 10px;
  }
  .Link {
    font-size: $small-font;
    color: $light-grey;
  }
  .DeleteIcon {
    cursor: pointer;
  }
}
