@import "scss/variables.scss";
.MenuWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}
.Nav {
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: rgb(51, 53, 71);
  background: linear-gradient(
    90deg,
    $sidebar-background-color 0%,
    $sidebar-background-color-2 100%
  );
  box-shadow: 5px 5px 10px rgb(0 0 0 / 50%);
  .LogoWrapper {
    position: relative;
    width: 88%;
    height: 100px;
    margin: 0 auto;
    padding-top: 15px;
    & > img {
      position: absolute;
      width: 100%;
      height: auto;
      max-width: 140px;
    }
  }
}
.List {
  color: #8699ad;
  transition: 0.3s ease;
  align-items: center;
  &:hover {
    color: $green;
  }
  .Button {
    gap: 10px;
    padding: 10px;
  }
}
.LogoutWrapper {
  cursor: pointer;
  color: $green;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: fit-content;
  margin-left: auto;
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 0.6;
  }
}
