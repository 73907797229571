@import "scss/variables";
.Card {
  position: relative;
  cursor: pointer;
  transition: transform 0.5s ease !important;
  .Titles {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .Desc {
    color: $dark-grey;
  }
  .Small {
    font-size: 0.8rem;
    color: $light-grey;
  }
  .DeleteButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: transform 0.5s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
}
