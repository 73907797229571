@import "scss/variables";

.ModalHeader {
  background: linear-gradient(
    90deg,
    $sidebar-background-color-2 0%,
    $sidebar-background-color 100%
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SmallText {
  font-size: 11px;
}
.TimeStamp {
  font-size: $small-font;
  text-align: right;
  color: $dark-grey;
}
.WorkbookTitle {
  font-weight: bold;
  margin-top: 20px;
}
.TextField {
  font-size: 12px;
}
.CloseIcon {
  cursor: pointer;
}
