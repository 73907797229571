//colors
$dark-grey: #5b626b;
$light-grey: #8699ad;
$table-header: #ececf1;
$sidebar-background-color: #333547;
$sidebar-background-color-2: #22232a;
$green: #02a499;
$dark-green: #057a72;
$red: #d95858;

//sizes
$small-font: 11px;
$regular-font: 14px;
$form-control-size: 180px;
