@import "scss/variables.scss";

body {
  margin: 0 !important;
  overflow-x: hidden;
}
html {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}
a {
  text-decoration: none;
  color: inherit;
}
.subtitles {
  color: $dark-grey;
}
.MuiDataGrid-row {
  cursor: pointer;
}
.MuiDataGrid-columnHeaders {
  background-color: $table-header;
  color: $dark-grey;
}
.MuiDataGrid-cell {
  color: $dark-grey;
}
.MuiDataGrid-columnHeader {
  font-weight: bold;
}
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}
.MuiSelect-select {
  font-size: $small-font !important;
}
fieldset {
  border: 1px solid $green !important;
  outline: $green !important;
}
.css-3baera-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: $green !important;
}
.css-ke5b6m-MuiButtonBase-root-MuiButton-root.MuiButton-containedSuccess,
.MuiFab-root {
  background: $green !important;
  &:hover {
    background: $dark-green !important;
  }
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 12px !important;
}
label {
  font-size: 12px !important;
  line-height: 1.8 !important;
}
.editorClassName {
  padding: 10px;
  border: 1px solid $table-header;
}
.MuiAccordionSummary-root {
  flex-direction: row-reverse !important;
  gap: 20px;
  justify-content: space-between !important;
}
.MuiAccordionSummary-content {
  justify-content: space-between !important;
}
.MuiCardActions-root {
  justify-content: space-between !important;
}
.rdw-option-wrapper {
  min-width: 17px !important;
}
