@import "scss/variables.scss";

.FormControl {
  width: $form-control-size;
}
.Container {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 50%);
  background: rgb(51, 53, 71);
  background: linear-gradient(
    90deg,
    $sidebar-background-color-2 0%,
    $sidebar-background-color 100%
  );
  height: 80px;
  align-items: center;
}
.Env {
  padding: 10px;
  background: $green;
  color: white;
  border-radius: 5px;
}
.ToggleButton {
  color: $light-grey !important;
}
