@import "scss/variables";

.FormControl {
  flex-grow: 1;
}
.Icon {
  cursor: pointer;
}
.FiltersWrapper {
  animation: open 0.5s ease forwards;
}

@keyframes open {
  from {
    opacity: 0;
    transform: translateX(100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
